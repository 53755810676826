import { container, title } from "assets/jss/material-kit-react.jsx";

const faqPageStyle = {
      container: {
        zIndex: "12",
        color: "#FFFFFF",
        marginTop: "150px",
        ...container           
      },
      img: {
        height: "90vh",
        width: "auto",
        overflow: "auto",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top center",
      },
      title: {
        ...title,
        display: "flex",
        position: "relative",
        marginTop: "30px",
        minHeight: "32px",
        color: "#FFFFFF",
        justifyContent: "center",
        textDecoration: "none"
      },
      subtitle: {
        fontSize: "1.313rem",
        maxWidth: "500px",
        margin: "10px auto 0"
      },
      main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
      },
      mainRaised: {
        margin: "-60px 30px 0px",
        borderRadius: "6px",
        boxShadow:
          "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
      },
    search: {
        display:"flex",
        flexDirection: "row",
        borderRadius: "5px",
        backgroundColor: "#009688",
        padding:"5px",
        margin:"5px",
        alignItems: "center",
        opacity:"95%",
    },
};

export default faqPageStyle;