import React from "react";

import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";

import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import faqAccordionStyle from "assets/jss/material-kit-react/views/faqAccordionStyle.jsx";


const dashboardRoutes = [];

class FAQAccordion extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <ExpansionPanel className = {classes.accordion}>
            <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            >
            <Typography><strong>How long is the free trial?</strong></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            <Typography>
                The free trial is seven days.
            </Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel className = {classes.accordion}>
            <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            >
            <Typography ><strong>How do I open a different book I'm working on?</strong></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            <Typography>
                Go to your book list, click on the book you'd like to work on, and press load.
                You will see the cover photo in the header of the page.
            </Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>
        
        <ExpansionPanel className = {classes.accordion}>
            <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            >
            <Typography ><strong>Can I send someone my book to edit?</strong></Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
            <Typography>
                Yes, you can send a request to someone you're connected with to edit your book.
                If they accept they will have access to make changes to your book. Once they are done editing
                they will send it back to you, where you will then have the choice to accept the changes or not.
            </Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

export default withStyles(faqAccordionStyle)(FAQAccordion);