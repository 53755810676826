import React from "react";
import Helmet from "react-helmet";
import window from "global";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";

import bookImage from "../../assets/img/dad.jpg";

import faqPageStyle from "assets/jss/material-kit-react/views/faqPageStyle.jsx";

import FAQAccordion from './Sections/FAQAccordion.jsx';



const bookmerlinImage = window.innerWidth >= 650 ? bookImage : bookImage;

const dashboardRoutes = [];

class FAQPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
      <Helmet>
        <title>BookMerlin</title>
      </Helmet>
        <Header
          color="transparent"
          routes={dashboardRoutes}
          brand="BookMerlin"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "#009688" //changed from white
          }}
          {...rest}
        />
        <div className={classes.img} style={{backgroundImage: `url(${bookmerlinImage})`}}>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={8}>
                <div >
                  <div >
                    <h1 className={classes.title} >How can we help you?</h1>
                    <div className={classes.search}>
                      <SearchIcon />
                      <InputBase
                        placeholder='Search...'
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </GridItem>
              <GridItem xs={12} sm={12} md={8}>
                <FAQAccordion />
              </GridItem>
            </GridContainer>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(faqPageStyle)(FAQPage);
